<h2>Modification Contact</h2>
<div class="wrapper">
  <form>
    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="nom">Nom :</label>
        <input [(ngModel)]="contact.nom" class="form-control" id="nom" name="nom" required>
      </div>
      <div class="form-group col-md-3 offset-md-1">
        <label for="prenom">Prénom :</label>
        <input [(ngModel)]="contact.prenom" class="form-control" id="prenom" name="prenom" required>
      </div>
    </div>

    <div class="form-row mt-4">
      <div class="form-group col-md-5">
        <label for="concession">Concession :</label>
        <ng-multiselect-dropdown id="concession" name="concession"
                                 [placeholder]="'Select'"
                                 [data]="dropdownConcessionList"
                                 [(ngModel)]="concessionSelected"
                                 [settings]="dropdownConcessionSettings"
                                 (onSelect)="onConcessionSelect($event)"
                                 (onDeSelect)="onConcessionDeselect($event)"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>

    <div class="form-row mt-4">
      <div class="form-group col-md-5">
        <label for="fonction">Fonction :</label>
        <ng-multiselect-dropdown id="fonction" name="fonction"  [(ngModel)]="fonctionSelected"
                                 [placeholder]="'Select'"
                                 [data]="dropdownFonctionList"
                                 [settings]="dropdownFonctionSettings"
                                 (onSelect)="onFonctionSelect($event)"
                                 (onDeSelect)="onFonctionDeselect($event)"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="form-group col-md-3">
        <label for="tel_fix">Ligne directe :</label>
        <input [(ngModel)]="contact.telephone_fixe" class="form-control" id="tel_fix" name="tel_fix">
      </div>
      <div class="form-group col-md-3 offset-md-1">
        <label for="tel_mobil">Téléphone Mobile :</label>
        <input [(ngModel)]="contact.telephone_portable" class="form-control" id="tel_mobil" name="tel_mobil">
      </div>
    </div>

    <div class="form-row mt-4">
      <div class="form-group col-md-5">
        <label for="prenom">Adresse email :</label>
        <input [(ngModel)]="contact.mail" class="form-control" id="email" name="email">
      </div>
    </div>

    <div class="form-row mt-4">
      <div class="form-group col-md-5">
        <label for="prenom">Commentaire :</label>
        <input [(ngModel)]="contact.commentaire" class="form-control" id="commentaire" name="commentaire">
      </div>
    </div>
    <div class="form-row mt-3">
      <small class="text-danger ml-4" *ngIf="formError">Veuillez remplir tous les champs</small>
    </div>
    <div class="btn-group mt-3 mb-5">
      <div>
        <button (click)="SelectChangeHandlerCreer()" class="btn btn100px btn-dark p-2 mr-2">Modifier</button>
      </div>

      <div>
        <button (click)="SelectChangeHandlerAnnuler()" class="btn btn-light btn100px border-dark p-2">Annuler</button>
      </div>
    </div>
  </form>
</div>
<app-spinner *ngIf="isLoading"></app-spinner>
