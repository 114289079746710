import { Component, OnInit } from '@angular/core';
import {DataInteractionService} from '../../utils/data-services/dataInteraction.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataModificationConcessionService} from '../../utils/data-services/dataModification-concession.service';
import swal from 'sweetalert2';
import { ConfirmationDialogComponent, ConfirmDialogModel } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { ExportToCsv } from 'export-to-csv';
import {DataIspaccService} from '../../utils/data-services/dataIspacc.service';
import {DataCreationConcessionService} from '../../utils/data-services/dataCreation-concession.service';
import { Produit } from 'src/app/consultation/valeur';
import {environment} from '../../../environments/environment';
import {DataConcessionService} from "../../utils/data-services/dataConcession.service";
// import {it} from '../../../../node_modules2/@types/selenium-webdriver/testing';

@Component({
  selector: 'app-modification-concession',
  templateUrl: './modification-concession.component.html',
  styleUrls: ['./modification-concession.component.css']
})
export class ModificationConcessionComponent implements OnInit {
  concession = {
    // concession_id: '',
    nom_concession: '',
    ville: '',
    ville_principale: '',
    rue: '',
    code_postal: null,
    tel: '',
    fax: '',
    pays: '',
    slug: '',
    raison_sociale: '',
    code_ej: null,
    code_site: null,
    outlet: null,
    district: null,
    groupe_id: null,
    longitude: null,
    latitude: null,
    has_kpi_kidi: false,
    has_soft_screen: false,
    has_auto_treatment: false,
    nb_days_min_auto_treatment: 4,
    dealership_type_id: null
  };
  concessionId: number;
  currentBrands: any;
  dropdownGroupSettings = {};
  dropdownGroupList = [];
  groupSelected = [];
  dropdownBrandSettings = {};
  dropdownBrandList = [];
  brandSelected = [];
  formError = false;
  week = [
    {name: 'Lundi', morningStart: '08:00', morningEnd: '12:00', afternoonStart: '14:00', afternoonEnd: '18:00', isClosed: false},
    {name: 'Mardi', morningStart: '08:00', morningEnd: '12:00', afternoonStart: '14:00', afternoonEnd: '18:00', isClosed: false},
    {name: 'Mercredi', morningStart: '08:00', morningEnd: '12:00', afternoonStart: '14:00', afternoonEnd: '18:00', isClosed: false},
    {name: 'Jeudi', morningStart: '08:00', morningEnd: '12:00', afternoonStart: '14:00', afternoonEnd: '18:00', isClosed: false},
    {name: 'Vendredi', morningStart: '08:00', morningEnd: '12:00', afternoonStart: '14:00', afternoonEnd: '18:00', isClosed: false},
    {name: 'Samedi', morningStart: '08:00', morningEnd: '12:00', afternoonStart: '14:00', afternoonEnd: '18:00', isClosed: false},
    {name: 'Dimanche', morningStart: '08:00', morningEnd: '12:00', afternoonStart: '14:00', afternoonEnd: '18:00', isClosed: true},
  ];
  hasMyBmw = false;

  /* End */
  commercialProductList: any;
  techniqueProductList: any;
  unsetCommercialProdutList: any;
  unsetTechniqueProdutList: any;
  concessionProduitID: any;
  versionConcessionProduitID: any;
  result = '';
  GlobalConfPMAJ: any;
  n = -1;
  array: any[] = [];
  indice: any = 0;
  nConcessionProduit = 0;
  slug: any;
  nbDayPurge = 7;
  hasSchedule = true;
  autoTreatmentWip = false;
  autoTreatmentJ1 = false;
  typesArray = [];
  isBmwDealership = true;

  //
  pstatusOpen = false;
  schedulePStatusOpen = false;
  ispaccPurgeOpen = false;
  ispaccPurgeLoading = false;
  pstatusCustomerResponseOpen = false;
  pmessageCustomerResponseOpen = false;
  imagePstatusOpen = false;


  //
  kidiOptionOpen = false;

  //
  pstatusOptions = {
    hasCustomerResponse: false,
    hasCustomerResponseEmail: false,
    customerResponseMaxIteration: 2,
    customerResponseStrictAfterComment: false,
    customerResponseAppointmentSAOnly: false,
  };
  hasMyBmwApp = false;
  hasTimelinePstatut = false;

  //
  pmessageOptions = {
    hasCustomerResponse: false,
    hasCustomerResponseEmail: false,
    customerResponseMaxIteration: 2,
    customerResponseStrictAfterComment: false,
    customerResponseAppointmentSAOnly: false,
  };

  // file
  fileData: any = '';
  fileNameFullsize = null;
  contentSrc: string | ArrayBuffer = '';

  // tslint:disable-next-line:max-line-length
  constructor(
    private dataModificationConcessionService: DataModificationConcessionService,
    private dataCreationConcessionService: DataCreationConcessionService,
    private dataConcessionService: DataConcessionService,
    private dataInteractionService: DataInteractionService, private route: Router,
    private activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private dataIspaccService: DataIspaccService
  ) {
    this.dropdownGroupSettings = {
      singleSelection: true,
      idField: 'groupe_id',
      textField: 'nom',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownBrandSettings = {
      singleSelection: false,
      idField: 'marque_id',
      textField: 'nom',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }

  ngOnInit() {
    this.concessionId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.getDealershipType();
    this.getGroups(() => {
      this.getBrands(() => {
        this.dataModificationConcessionService.getAutoTreatmentRulesConcession(this.concessionId).subscribe(autoTreatmentRulesConcession => {
          for (const item of autoTreatmentRulesConcession.records) {
            if ([1,4,6,8].includes(item.rule_id)) {
              this.autoTreatmentWip = true;
            } else if (item.rule_id == 3){
              this.autoTreatmentJ1 = true;
            }
          }
        });
        this.dataModificationConcessionService.getConcessionById(this.concessionId).subscribe(concession => {
          this.groupSelected = this.dropdownGroupList.filter(x => x.groupe_id === concession.groupe_id);
          this.isBmwDealership = !concession.code_site.startsWith('ex');
          this.concession = {
            // concession_id: '',
            nom_concession: concession.nom_concession,
            ville: concession.ville,
            ville_principale: concession.ville_principale,
            rue: concession.rue,
            code_postal: concession.code_postal,
            tel: concession.tel,
            fax: concession.fax,
            pays: concession.pays,
            slug: concession.slug,
            raison_sociale: concession.raison_sociale,
            code_ej: concession.code_ej,
            code_site: concession.code_site,
            outlet: concession.outlet,
            district: concession.district,
            groupe_id: concession.groupe_id,
            longitude: concession.longitude,
            latitude: concession.latitude,
            has_kpi_kidi: concession.has_kpi_kidi,
            has_soft_screen: concession.has_soft_screen,
            has_auto_treatment: concession.has_auto_treatment,
            nb_days_min_auto_treatment : concession.nb_days_min_auto_treatment,
            dealership_type_id: concession.dealership_type_id
          };
        });
        this.dataModificationConcessionService.getConcessionMarque(this.concessionId).subscribe(concessionMarqueArray => {
          const selectedBrand = [];
          this.currentBrands = concessionMarqueArray.records;
          for (const concessionMarque of concessionMarqueArray.records) {
            selectedBrand.push(this.dropdownBrandList.filter(x => x.marque_id === concessionMarque.marque_id)[0]);
          }
          this.brandSelected = selectedBrand;
        });

        this.dataModificationConcessionService.getConcessionProduit(this.concessionId).subscribe(productList => {
          this.commercialProductList = productList.records.filter(x => {
            return x.type == 'commercial';
          });
          this.techniqueProductList = productList.records.filter(x => {
            return x.type == 'technique';
          });
        });
      });
    });
  }
  getDealershipType() {
    this.dataConcessionService.getDealershipType().subscribe(result => {
      this.concession.dealership_type_id = result.length > 0  ? result[0].id : null;
      this.typesArray = result;
    });
  }
  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1 === o2 : o2 === o2;
  }
  getGroups(callback?): void {
    this.dataCreationConcessionService.getGroups().subscribe(groups => {
      this.dropdownGroupList = groups.records;
      if (callback)
        callback();
    });
  }
  getBrands(callback?) {
    this.dataCreationConcessionService.getBrands().subscribe(brands => {
      this.dropdownBrandList = brands.records.filter(x => x.marque_id != 1);
      if (callback)
        callback();
    });
  }
  onClickIsBmwDS(): any {
    setTimeout(() => {
      if (!this.isBmwDealership) {
        // this.concession.code_site = null;
        this.concession.outlet = null;
        this.concession.code_ej = null;
        this.concession.district = null;
      }
    }, 100);
  }
  onGroupSelect(item: any) {
    this.groupSelected = [item];
    this.concession.groupe_id = item.groupe_id;
  }

  onGroupDeselect(item: any) {
    this.groupSelected = [this.dropdownGroupList[0]];
    this.concession.groupe_id = 1;
  }
  onBrandSelect(item: any) {
    if (!this.brandSelected.find(x => x === item)) {
      this.brandSelected.push(item);
      this.brandSelected.splice(this.brandSelected.length - 1, 1);
    }
  }
  onBrandDeselect(item: any) {
    for (let i = 0; i < this.brandSelected.length; i++) {
      if (this.brandSelected[i] === item) {
        this.brandSelected.splice(i, 1);
      }
    }
  }
  toggleOptionsKidi(): void {
    this.kidiOptionOpen = !this.kidiOptionOpen;
  }
  togglePstatus(): void {
    this.pstatusOpen = !this.pstatusOpen;
    if (this.pstatusOpen) {
      this.dataIspaccService.requestGetConcessionsById(this.concessionId).subscribe(res => {
        this.hasMyBmwApp = res.has_my_bmw;
        this.hasTimelinePstatut = res.has_timeline;
        if (res.schedule) {
          this.week = JSON.parse(res.schedule);
          this.hasSchedule = true;
        } else {
          this.hasSchedule = false;
        }
        this.hasMyBmw = res.has_my_bmw;
        this.pstatusOptions.hasCustomerResponse = res.has_customer_response;
        this.pstatusOptions.hasCustomerResponseEmail = res.has_customer_response_email;
        this.pmessageOptions.hasCustomerResponse = res.has_customer_response;
        this.pmessageOptions.hasCustomerResponseEmail = res.has_customer_response_email;
        if (res.customer_response_options_code) {
          this.pstatusOptions.customerResponseMaxIteration = parseInt(res.customer_response_options_code[0]);
          this.pstatusOptions.customerResponseStrictAfterComment = parseInt(res.customer_response_options_code[1]) > 0;
          this.pstatusOptions.customerResponseAppointmentSAOnly = parseInt(res.customer_response_options_code[2]) > 0;
        }
        if (res.pilot_message_response_options_code) {
          this.pmessageOptions.customerResponseMaxIteration = parseInt(res.pilot_message_response_options_code[0]);
          this.pmessageOptions.customerResponseStrictAfterComment = parseInt(res.pilot_message_response_options_code[1]) > 0;
          this.pmessageOptions.customerResponseAppointmentSAOnly = parseInt(res.pilot_message_response_options_code[2]) > 0;
        }
      });
    }
  }
  togglePurgeIspacc(): void {
    this.ispaccPurgeOpen = !this.ispaccPurgeOpen;
  }
  togglePstatusCustomerResponse(): void {
    this.pstatusCustomerResponseOpen = !this.pstatusCustomerResponseOpen;
  }
  togglePmessageCustomerResponse(): void {
    this.pmessageCustomerResponseOpen = !this.pmessageCustomerResponseOpen;
  }
  toggleImagePstatus(): void {
    this.imagePstatusOpen = !this.imagePstatusOpen;
    if (this.imagePstatusOpen) {
      this.dataIspaccService.requestGetImagePstatusByConcessionId(this.concessionId).subscribe(res => {
        this.contentSrc = res.base64 ? 'data:image/' + res.ext + ';base64,' + res.base64 : '';
      });
    }
  }
  togglePStatusSchedule(): void {
    this.schedulePStatusOpen = !this.schedulePStatusOpen;
  }
  scheduleIsClose(dayName): void {
    this.week.filter(x => x.name == dayName)[0].isClosed = !this.week.filter(x => x.name == dayName)[0].isClosed;
  }
  uploadPstatusImage(fileInputEvent): any {
    const file = fileInputEvent.target.files[0];
    if (!file.type.split('/').includes('jpg') && !file.type.split('/').includes('jpeg') && !file.type.split('/').includes('png')) {
      swal.fire({
        title: 'Attention',
        text: 'Fichier incompatible. Le fichier doit être de type jpeg, jpg ou png.',
        type: 'error',
        confirmButtonText: 'Ok',
      });
    } else if ((file.size / 1048576) <= 5) { // max file size is 5 mb
      const formData = new FormData();
      const info = {
        concession_id: this.concessionId,
        file_size: file.size,
        file_type: file.type,
        uploaded_by: sessionStorage.getItem('userName'),
      };
      formData.append('file', file, file.name);
      formData.append('info', JSON.stringify(info));
      const reader = new FileReader();
      this.fileNameFullsize = file.name;
      this.fileData = formData;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.contentSrc = reader.result;
      };
      this.dataIspaccService.requestUploadImagePstatus(formData).subscribe(res => {
        swal.fire({
          title: 'Fichier enregistré',
          text: 'Le fichier a été importé avec succès.',
          type: 'success',
          confirmButtonText: 'Ok',
        });
      }, err => {
        swal.fire({
          title: 'Attention',
          text: 'Un problème est survenu lors de l\'importation du fichier, veuillez réessayer ultérieurement.',
          type: 'error',
          confirmButtonText: 'Ok',
        });
      });
    } else {
      swal.fire({
        title: 'Attention',
        text: 'Fichier trop large. La taille maximal est 5mb.',
        type: 'error',
        confirmButtonText: 'Ok',
      });
    }
  }
  deletePstatutImage(): any {
    this.dataIspaccService.requestDeleteImagePstatus(this.concessionId).subscribe(res => {
      this.contentSrc = '';
      this.fileNameFullsize = '';
      this.fileData = null;
      swal.fire({
        title: 'Supprimé',
        text: 'L\'image a été supprimé avec succès.',
        type: 'success',
        confirmButtonText: 'Ok',
      });
    }, err => {
      swal.fire({
        title: 'Attention',
        text: 'Un problème est survenu lors de la suppression de l\'image, veuillez réessayer ultérieurement.',
        type: 'error',
        confirmButtonText: 'Ok',
      });
    });
  }
  setHasTimeline(): any {
    this.hasTimelinePstatut = !this.hasTimelinePstatut;
    const body = {dealershipId: this.concessionId, hasTimeline: this.hasTimelinePstatut};
    this.dataIspaccService.requestUpdateConcessionSetHasTimeline(body).subscribe(res => {
      // success
    }, error => {
      swal.fire({
        title: 'Erreur!',
        text: 'Un problème est survenu lors de la modification',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  setHasMyBmwApp(): any {
    this.hasMyBmwApp = !this.hasMyBmwApp;
    const body = {dealershipId: this.concessionId, hasMyBmwApp: this.hasMyBmwApp};
    this.dataIspaccService.requestUpdateConcessionSetHasBMWApp(body).subscribe(res => {
      // success
    }, error => {
      swal.fire({
        title: 'Erreur!',
        text: 'Un problème est survenu lors de la modification',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  submitSchedule(): void {
    const body = {schedule: JSON.stringify(this.week)};
    this.dataIspaccService.requestUpdateConcessionsById(this.concessionId, body).subscribe(res => {
      this.hasSchedule = true;
      swal.fire({
        title: '',
        text: 'Les horaires d\'atelier pour pilot-statut ont bien été enregistrés',
        type: 'success',
        confirmButtonText: 'Ok'
      });
    }, error => {
      swal.fire({
        title: 'Oops...!',
        text: 'Une erreur est surnevue lors de la sauvegarde..',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  submitConfigCustomerResponse(): void {
    const body = {
      has_customer_response: this.pstatusOptions.hasCustomerResponse,
      has_customer_response_email: this.pstatusOptions.hasCustomerResponseEmail,
      customer_response_options_code: null,
    };
    let customerResponseOptionCode = null;
    if (this.pstatusOptions.hasCustomerResponse) {
      customerResponseOptionCode = this.pstatusOptions.customerResponseMaxIteration.toString()
      customerResponseOptionCode += this.pstatusOptions.customerResponseStrictAfterComment ? '1' : '0';
      customerResponseOptionCode += this.pstatusOptions.customerResponseAppointmentSAOnly ? '1' : '0';
    }
    body.customer_response_options_code = customerResponseOptionCode;

    this.dataIspaccService.requestUpdateConcessionsById(this.concessionId, body).subscribe(res => {
      swal.fire({
        title: '',
        text: 'La configuration réponse client de pilot-statut a bien été enregistrée',
        type: 'success',
        confirmButtonText: 'Ok'
      });
    }, error => {
      swal.fire({
        title: 'Oops...!',
        text: 'Une erreur est surnevue lors de la sauvegarde..',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  submitConfigPMessageCustomerResponse(): void {
    const body = {
      has_pilot_message_response: this.pmessageOptions.hasCustomerResponse,
      has_pilot_message_response_email: this.pmessageOptions.hasCustomerResponseEmail,
      pilot_message_response_options_code: null,
    };
    let customerResponseOptionCode = null;
    if (this.pmessageOptions.hasCustomerResponse) {
      customerResponseOptionCode = this.pmessageOptions.customerResponseMaxIteration.toString();
      customerResponseOptionCode += this.pmessageOptions.customerResponseStrictAfterComment ? '1' : '0';
      customerResponseOptionCode += this.pmessageOptions.customerResponseAppointmentSAOnly ? '1' : '0';
    }
    body.pilot_message_response_options_code = customerResponseOptionCode;

    this.dataIspaccService.requestUpdateConcessionsById(this.concessionId, body).subscribe(res => {
      swal.fire({
        title: '',
        text: 'La configuration réponse client de pilot-statut a bien été enregistrée',
        type: 'success',
        confirmButtonText: 'Ok'
      });
    }, error => {
      swal.fire({
        title: 'Oops...!',
        text: 'Une erreur est surnevue lors de la sauvegarde..',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  onClickCreateGroup() {
    swal.fire({
      title: 'Entrer le nom du nouveau groupe',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'valider',
      showLoaderOnConfirm: true,
      preConfirm: (groupName) => {
        const body = {nom: groupName};
        this.dataCreationConcessionService.createGroup(body).subscribe(res => {
          this.dataCreationConcessionService.sauronDispatchGroup(res).subscribe();
          this.getGroups(() => {
            this.onGroupSelect(this.dropdownGroupList.find(x => x.groupe_id === res));
          });
          this.concession.groupe_id = res;
          swal.fire({
            title: 'Success!',
            text: 'Le groupe ' + groupName + ' a bien été créé',
            type: 'success',
            confirmButtonText: 'Ok'
          });
        }, error => {
          swal.fire({
            title: 'Erreur!',
            text: 'Un problème est survenu lors de la création du groupe',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    });
  }
  /***
   * Delete product for a concession
   * Parameter: produit_id, Type: Int
   * Parameter: nom_produit, Type: String
   ***/
  dialogProduit(arg: any, arg1: any): void {
    const message = ' Etes vous sûre de vouloir supprimer ' + arg1 + ' ? ';
    const dialogData = new ConfirmDialogModel('Confirmer l\'action', message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      height: '200px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if (this.result) {
        this.deleteProduit(arg);
      }
    });
  }

  /***
   * Initiate concession product version
   * Parameter: produit_id, Type: Int
   ***/
  setVersion(arg: any) {
    this.dataModificationConcessionService.getIdConcessionProduit(this.concessionId, arg).subscribe(data => { this.versionConcessionProduitID = data.records;
      this.dataModificationConcessionService.updateVersion(this.versionConcessionProduitID[0].concession_produit_id).subscribe(data1 => {
        swal.fire({
          title: 'Success!',
          text: 'Version a bien été réinitialisée',
          type: 'success',
          showConfirmButton: false
        });
        setTimeout(() => {
            window.location.reload();
          },
          1000);
      });
    });
  }

  /***
   * Retrieve all products for a concession and only display the ones that don't belong to the concession
   ***/
  SelectChangeHandlerAjouterCommercial() {
    this.dataModificationConcessionService.getProduitPourConcession(this.concessionId).subscribe(produitConsession => {
      this.dataModificationConcessionService.getProduit().subscribe( products => {
        const productList = []
        // tslint:disable-next-line:prefer-for-of
        for (let product of products.records) {
          if (!((produitConsession.records).some(x => x.produit_id === product.produit_id ))) {
            if (product.type == 'commercial') {
              productList.push(product);
              this.nConcessionProduit++;
            }
          }
        }
        this.unsetCommercialProdutList = productList;
      });
    });
  }
  SelectChangeHandlerAjouterTechnique() {
    this.dataModificationConcessionService.getProduitPourConcession(this.concessionId).subscribe(produitConsession => {
      this.dataModificationConcessionService.getProduit().subscribe( products => {
        const productList = []
        // tslint:disable-next-line:prefer-for-of
        for (let product of products.records) {
          if (!((produitConsession.records).some(x => x.produit_id === product.produit_id ))) {
            if (product.type == 'technique') {
              productList.push(product);
              this.nConcessionProduit++;
            }
          }
        }
        this.unsetTechniqueProdutList = productList;
      });
    });
  }
  /***
   * Delete concession product relation
   * Parameter: produit_id, Type: Int
   ***/
  deleteProduit(arg: any) {
    this.dataModificationConcessionService.getIdConcessionProduit(this.concessionId, arg).subscribe(data => {
      this.concessionProduitID = data.records;
      // tslint:disable-next-line:max-line-length
      this.dataModificationConcessionService.deleteConcessionProduit((this.concessionProduitID)[0].concession_produit_id).subscribe( data1 => {
        swal.fire({
          title: 'Success!',
          text: 'Produit a bien été supprimé',
          type: 'success',
          showConfirmButton: false
        });
        setTimeout(() => {
            window.location.reload();
          },
          1000);
      });
    });
  }

  SelectChangeHandlerAnnuler() {
    this.route.navigate(['../gestion-concession']);
  }
  rechargerPage() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.array.length; i++) {
      this.ajouterProduit(this.array[i].value);
    }
    setTimeout(() => {
        window.location.reload();
      },
      2000);
  }

  /***
   * Add concession product relation
   * Parameter: produit_id, Type: Int
   ***/
  ajouterProduit(arg: any) {
    this.dataModificationConcessionService.addConcessionProduit(this.concessionId, arg).subscribe(data => {
      swal.fire({
        title: 'Success!',
        text: 'Produit a été bien rajouté',
        type: 'success',
        showConfirmButton: false
      });
    });
  }
  checkForm(): any {
    this.formError = false;
    let notRequiredFields = ['latitude', 'longitude', 'district', 'fax', 'has_kpi_kidi', 'has_soft_screen', 'has_auto_treatment'];
    if (!this.isBmwDealership) {
      notRequiredFields = [...notRequiredFields, ...['code_ej', 'code_site', 'outlet', 'district']];
    }
    for (const item of Object.entries(this.concession)) {
      if (!notRequiredFields.includes(item[0]) && (item[1] == '' || item[1] == null)) {
        this.formError = true;
      }
    }
    if (this.groupSelected.length == 0) {
      this.formError = true;
    }
    return !this.formError;
  }
  /***
   * Update concession ProductList values
   ***/
  SelectChangeHandlerSoumettre(redirection) {
    this.formError = false;
    this.concession.fax = this.concession.tel;
    this.concession.groupe_id = parseInt(this.concession.groupe_id);
    const body = JSON.stringify(this.concession);
    if (!this.formError) {
      if (this.autoTreatmentJ1) {
          // rule id j-1 ==> 3
        const bodyAutoTreatmentJ1 = JSON.stringify({concession_id: this.concessionId, rule_id: 3});
        this.dataModificationConcessionService.postAutoTreatmentRulesConcession(bodyAutoTreatmentJ1).subscribe(res => {}, error => {
          swal.fire({
            title: 'Un problème est survenu!',
            text: 'Un problème est survenu lors de l\'enregistrement du paramétrage du traitement automatique des notifications pour la règle j-1 (id: 3)!',
            confirmButtonText: 'Ok'
          });
        });
      } else {
        const bodyAutoTreatmentJ1 = JSON.stringify({concession_id: this.concessionId, rule_id: 3});
        this.dataModificationConcessionService.deleteAutoTreatmentRulesConcession(bodyAutoTreatmentJ1).subscribe();
      }
      if (this.autoTreatmentWip) {
          // rules work in progress ==> 1,4,6,8
        for (const i of [1, 4, 6, 8]) {
          const bodyAutoTreatmentWip = JSON.stringify({concession_id: this.concessionId, rule_id: i});
          this.dataModificationConcessionService.postAutoTreatmentRulesConcession(bodyAutoTreatmentWip).subscribe(res => {}, err => {
            swal.fire({
              title: 'Un problème est survenu!',
              text: 'Un problème est survenu lors de l\'enregistrement du paramétrage du traitement automatique des notifications pour la règle id: '+i+'!',
              confirmButtonText: 'Ok'
            });
          });
        }
      } else {
        for (const i of [1, 4, 6, 8]) {
          const bodyAutoTreatmentWip = JSON.stringify({concession_id: this.concessionId, rule_id: i});
          this.dataModificationConcessionService.deleteAutoTreatmentRulesConcession(bodyAutoTreatmentWip).subscribe();
        }
      }
      this.dataModificationConcessionService.updateConcession(body, this.concessionId).subscribe( data => {
        // Sauron
        this.dataModificationConcessionService.callSauron(this.concessionId).subscribe(res => {
          // Set error message
          let errorMessage = '<strong>Rapport d\'erreur Sauron</strong>';
          if (res.ispacc.error) {errorMessage += '<div><strong>ispacc</strong> : ' + res.ispacc.message + '</div>'}
          if (res.altibaseV2.error) {errorMessage += '<div><strong>altibaseV2</strong> : ' + res.altibaseV2.message + '</div>'}
          if (res.softPlanning.error) {errorMessage += '<div><strong>softPlanning</strong> : ' + res.softPlanning.message + '</div>'}
          if (res.dora.error) {errorMessage += '<div><strong>dora</strong> : ' + res.dora.message + '</div>'}
          if (res.soPlanning.error) {errorMessage += '<div><strong>soPlanning</strong> : ' + res.soPlanning.message + '</div>'}
          if (res.bmwScoringDistant.error) {errorMessage += '<div><strong>bmwScoringDistant</strong> : ' + res.bmwScoringDistant.message + '</div>'}
          // check error
          if (errorMessage != '<strong>Rapport d\'erreur Sauron</strong>') {
            swal.fire({
              title: 'Un problème est survenu lors du dispatch sauron!',
              html: errorMessage,
              confirmButtonText: 'Ok'
            });
          }
        });
        for (const brand of this.brandSelected) {
          const bodyMarque = {concession_id: this.concessionId, marque_id: parseInt(brand.marque_id)};
          if (!this.currentBrands.find(x => x.marque_id === bodyMarque.marque_id)) {
            this.dataCreationConcessionService.addConcessionMarque(bodyMarque).subscribe(res => {
            }, error => {
              swal.fire({
                title: 'Erreur!',
                text: 'Un problème est survenu lors du mapping pngo',
                type: 'error',
                confirmButtonText: 'Ok'
              });
            });
          }
          setTimeout(() => {});
        }
        for (const concessionMarque of this.currentBrands) {
          if (!this.brandSelected.find(x => x.marque_id === concessionMarque.marque_id)) {
            this.dataCreationConcessionService.removeConcessionMarque(concessionMarque.concession_marque_id).subscribe(res => {
            }, error => {
              swal.fire({
                title: 'Erreur!',
                text: 'Un problème est survenu lors du mapping pngo',
                type: 'error',
                confirmButtonText: 'Ok'
              });
            });
          }
        }
        swal.fire({
          title: 'Success!',
          text: 'Concession a bien été modifiée',
          type: 'success',
          confirmButtonText: 'Ok'
        });
        if (redirection) {
          this.route.navigate(['../gestion-concession']);
        }
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la création de la concession',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }

  /***
   * Create a product object array to be added to concession based on checkbox selection
   ***/
  checkClick($event: any) {
    if ($event.target.checked) {
      this.n++;
      this.array[this.n] = {key: 'softgateAjouterProduitId' + $event.target.value, value: $event.target.value};
    } else if (!($event.target.checked)) {
      this.indice = this.array.findIndex(item => item.value === $event.target.value);
      this.array.splice(this.indice, 1);
      this.n--;
    }
  }

  /***
   * Download globale_conf ProductList values
   ***/
  SelectChangeHandlerPMAJ() {
    this.dataModificationConcessionService.getValeurPMAJ(this.concessionId).subscribe(data => {
      this.GlobalConfPMAJ = data.records;
      const options = {
        fieldSeparator: ';',
        quoteStrings: '',
        filename: 'globale_conf',
        useTextFile: false,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(this.GlobalConfPMAJ);
    })
  }

  onClickGoogleMap() {
    const adresse = this.concession.rue + ', ' + this.concession.code_postal + ' ' + this.concession.ville;
    const url = 'https://www.google.fr/maps/place/' + adresse.replace(' ', '+');
    window.open(url, '_blank');
  }

  onPurgeAppointments(idConcession): void {
    swal.fire({
      title: 'Attention!',
      text: 'Vous êtes sur le point de désactiver les rendez-vous ISPACC qui ont une date de réception antérieure à ' + this.nbDayPurge + ' jours',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Ok'
    }).then(res => {
      if (res.value) {
        this.ispaccPurgeLoading = true;
        this.dataIspaccService.requestPurgeAppointments(idConcession, this.nbDayPurge).subscribe(() => {
          this.ispaccPurgeLoading = false;
          swal.fire({
            text: 'La purge des rendez-vous à été effectuée avec succès.',
            type: 'success',
            confirmButtonText: 'Ok'
          });
        }, error => {
          swal.fire({
            text: 'Une erreur s\'est produite lors de la purge des rendez-vous. veuillez réessayer ultérieurement.',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    });
  }
  loadInBackground() {
    this.ispaccPurgeLoading = false;
  }





  selectTechCom(type): any {

  }

}
