import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../handleError.service';

@Injectable({
  providedIn: 'root'
})
export class DataIspaccService extends HandleErrorService {
  apiIspacc = environment.ispaccUrlDomaine + '/api/resources/records/';
  purgeUrl = environment.ispaccUrlDomaine + '/api/purge/appointments/';
  private errorMessage: string;

  requestGetConcessionsById(concessionId): Observable<any> {
    return this.http.get<any>(this.apiIspacc + 't_concession/' + concessionId, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestUpdateConcessionsById(concessionId, body): Observable<any> {
    return this.http.put<any>(this.apiIspacc + 't_concession/' + concessionId, JSON.stringify(body), environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestUpdateConcessionSetHasTimeline(body): Observable<any> {
    return this.http.put<any>(environment.ispaccUrlDomaine + '/api/dealership/set-has-timeline', JSON.stringify(body), environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestUpdateConcessionSetHasBMWApp(body): Observable<any> {
    return this.http.put<any>(environment.ispaccUrlDomaine + '/api/dealership/set-has-my-bmw', JSON.stringify(body), environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestGetCorrespondanceStatutsByConcessionId(concessionId): Observable<any> {
    return this.http.get<any>(this.apiIspacc + 't_correspondance_statuts?filter=id_concession,eq,' + concessionId, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestDeleteCorrespondanceStatutsById(statusId): Observable<any> {
    return this.http.delete<any>(this.apiIspacc + 't_correspondance_statuts/' + statusId, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestCreateCorrespondanceStatuts(post): Observable<any> {
    const body = JSON.stringify(post);
    return this.http.post<any>(this.apiIspacc + 't_correspondance_statuts', body, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestUpdateCorrespondanceStatuts(id, post): Observable<any> {
    const body = JSON.stringify(post);
    return this.http.put<any>(this.apiIspacc + 't_correspondance_statuts/' + id, body, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestPurgeAppointments(idConcession, nbJours): Observable<any> {
    return this.http.get<any>(this.purgeUrl + idConcession + '/' + nbJours, environment.httpOptionsSGApi).pipe(
      catchError(this.handleError)
    );
  }
  requestUploadImagePstatus(data): Observable<any> {
    return this.http.post<any>(environment.ispaccUrlDomaine + '/api/upload-image-pstatus', data, environment.httpOptionSimple).pipe(
      catchError(this.handleError)
    );
  }
  requestDeleteImagePstatus(concessionId): Observable<any> {
    return this.http.delete<any>(environment.ispaccUrlDomaine + '/api/delete-image-pstatus/' + concessionId, environment.httpOptionSimple).pipe(
      catchError(this.handleError)
    );
  }
  requestGetImagePstatusByConcessionId(concessionId): Observable<any> {
    return this.http.get<any>(environment.ispaccUrlDomaine + '/api/get-image-pstatus/' + concessionId, environment.httpOptionSimple).pipe(
      catchError(this.handleError)
    );
  }

}
