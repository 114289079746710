<h2>Modification Concession</h2>
<h3>{{concessionId}} - {{concession.nom_concession}} - {{concession.ville}}</h3>
<div class="w-100">
<div class="row">
    <div>
      <!-- Form Dealership -->
      <div class="wrapper">
    <form class="small-form">
          <div class="form-row mt-4">
            <!--        <div class="form-group col-md-1">-->
            <!--          <label for="id">ID :</label>-->
            <!--          <textarea [(ngModel)]="id" class="form-control" id="id" name="id" rows="1" no-auto-shrink></textarea>-->
            <!--        </div>-->
            <div class="form-group col-md-4">
              <label for="nom-concession">Nom concession *</label>
              <input [(ngModel)]="concession.nom_concession" placeholder="Nom concession *" class="form-control" id="nom-concession" name="nom-concession"
                required="required">
            </div>
            <div class="form-group col-md-2 offset-md-1">
              <label for="slug">Slug *</label>
              <input [(ngModel)]="concession.slug" placeholder="Slug *" class="form-control" id="slug" name="slug" required="required">
            </div>
          </div>
          <div class="form-row mt-1">
            <div class="mt-3 col-6 form-group">
              <input type="checkbox" [(ngModel)]="isBmwDealership" (click)="onClickIsBmwDS()" class="checkbox-med" id="isBmw"
                     name="isBmw">
              <label for="isBmw" class="ml-3">Concession BMW</label>
            </div>
          </div>
          <div class="form-row mt-3">
                <div class="form-group col-md-4">
                  <label for="raisonSocial">Raison sociale *</label>
              <input [(ngModel)]="concession.raison_sociale" placeholder="Raison sociale *" class="form-control" id="raisonSocial" name="raisonSocial"
                required="required" disabled>
              </div>
              <div class="form-group col-md-2 offset-md-1" *ngIf="isBmwDealership">
                <label for="codeSite">Code site *</label>
                <input [(ngModel)]="concession.code_site" placeholder="Code Site *" class="form-control" id="codeSite" name="codeSite" required="required">
              </div>
          </div>
          <div class="form-row mt-3" *ngIf="isBmwDealership">
                <div class="form-group col-md-2">
                  <label for="raisonSocial">Code EJ *</label>
                  <input [(ngModel)]="concession.code_ej" placeholder="Code EJ *" class="form-control" id="codeEj" name="codeEj" required="required">
                </div>
                <div class="form-group col-md-2">
                  <label for="raisonSocial">Outlet *</label>
                  <input [(ngModel)]="concession.outlet" placeholder="Outlet *" class="form-control" id="outlet" name="outlet" required="required">
                </div>
                <div class="form-group col-md-2 offset-md-1">
                  <label for="raisonSocial">District</label>
                  <input [(ngModel)]="concession.district" placeholder="District" class="form-control" id="district" name="district">
                </div>
          </div>

          <div class="form-row mt-3">
                <div class="form-group col-md-4">
                  <label for="group">Groupe *</label>
              <ng-multiselect-dropdown id="group" name="group" [(ngModel)]="groupSelected" [placeholder]="'Choisir un groupe *'"
                [data]="dropdownGroupList" [settings]="dropdownGroupSettings" (onSelect)="onGroupSelect($event)"
                (onDeSelect)="onGroupDeselect($event)">
              </ng-multiselect-dropdown>
              </div>
              <div class="col-md-2 offset-md-1">
                <button class="btn btn-dark mt-4 p-2 mr-2" (click)="onClickCreateGroup()">Ajouter Groupe</button>
              </div>
          </div>

          <div class="form-row mt-4">
                <div class="form-group col-md-4">
                  <label for="rue">Adresse *</label>
                  <input [(ngModel)]="concession.rue" placeholder="Adresse *" class="form-control" id="rue" name="rue" required="required">
                </div>
                <div class="form-group col-md-2 offset-md-1">
                  <label for="codeP">Code postal *</label>
                  <input [(ngModel)]="concession.code_postal" placeholder="CP *" class="form-control" id="codeP" name="codeP" required="required">
                </div>
          </div>

          <div class="form-row mt-3">
                <div class="form-group col-md-3">
                  <label for="ville">Ville *</label>
                  <input [(ngModel)]="concession.ville" placeholder="Ville *" class="form-control" id="ville" name="ville" required="required">
                </div>
                <div class="form-group col-md-3 offset-md-1">
                  <label for="villeP">Ville principale *</label>
                  <input [(ngModel)]="concession.ville_principale" placeholder="Ville Principale *" class="form-control" id="villeP" name="villeP" required="required">
                </div>
          </div>

          <div class="form-row mt-3">
                <div class="form-group col-md-2">
                  <label for="pays">Pays *</label>
                  <input [(ngModel)]="concession.pays" placeholder="Pays *" class="form-control" id="pays" name="pays" required="required">
                </div>
                <div class="form-group col-md-2 offset-md-3">
                  <label for="tel">Téléphone *</label>
                  <input [(ngModel)]="concession.tel" placeholder="Téléphone *" class="form-control" id="tel" name="tel" required="required">
                </div>
          </div>

          <div class="form-row mt-3">
                <div class="form-group col-md-3">
                  <label for="latitude">Latitude</label>
                  <input [(ngModel)]="concession.latitude" placeholder="Latitude" class="form-control" id="latitude" name="latitude">
                </div>
                <div class="form-group col-md-3 offset-md-1">
                  <label for="longitude">Longitude</label>
                  <input [(ngModel)]="concession.longitude" placeholder="Longitude" class="form-control" id="longitude" name="longitude">
                </div>
          </div>
          <div class="row">
                <button class="btn btn-dark p-2 ml-3" (click)="onClickGoogleMap()">Voir l'adresse sur google map</button>
          </div>
          <div class="form-row mt-3">
                <div class="form-group col-md-4">
                  <label for="group">Marque(s)</label>
              <ng-multiselect-dropdown id="marque" name="marque" [placeholder]="'Select'" [data]="dropdownBrandList"
                [(ngModel)]="brandSelected" [settings]="dropdownBrandSettings" (onSelect)="onBrandSelect($event)"
                (onDeSelect)="onBrandDeselect($event)">
              </ng-multiselect-dropdown>
              </div>
            <div class="form-group col-md-4">
              <label for="dealership-type">Type</label>
              <mat-select class="form-control" id="dealership-type" [(value)]="concession.dealership_type_id">
                <mat-option *ngFor="let type of typesArray"  [value]="type.id">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="btn-group mt-3 mb-2">
              <div>
                <button (click)="SelectChangeHandlerPMAJ()" class="btn btn-warning p-2 mr-2">Conf PMAJ</button>
              </div>

              <div>
                <button (click)="SelectChangeHandlerSoumettre(true)" [disabled]="!checkForm()" class="btn btn100px btn-dark p-2 mr-2">Modifier</button>
              </div>

              <div>
                <button (click)="SelectChangeHandlerAnnuler()" class="btn btn100px border-dark btn-light p-2">Annuler</button>
              </div>
          </div>
      <div class="form-row mt-3 mb-3">
        <small class="text-danger ml-4" *ngIf="formError">Veuillez remplir tous les champs requis et le groupe</small>
      </div>
    </form>
    </div>
      <!-- Options KIDI -->
      <div class="wrapper block-container">
        <div>
          <div class="header-block d-flex justify-content-between" (click)="toggleOptionsKidi()"><span>Options KIDI</span> <mat-icon>keyboard_arrow_down</mat-icon></div>
          <div class="d-flex input-ispacc justify-content-around flex-column" *ngIf="kidiOptionOpen">
            <div class="m-3">
              <div class="form-row mt-3">
                <div class="ml-2 mt-3 col-10 form-group d-flex">
                  <input type="checkbox" [(ngModel)]="concession.has_kpi_kidi" class="form-control" id="has_kpi_kidi" name="has_kpi_kidi"
                         class="checkbox-med ml-3">
                  <label for="has_kpi_kidi" class="ml-3">KPIs Kidi visibles dans le tableau général</label>
                </div>
                <!--              <div class="ml-2 col-6  form-group">-->
                <!--              <input type="checkbox" [(ngModel)]="concession.has_soft_screen" class="form-control" id="has_soft_screen"-->
                <!--                name="has_soft_screen" class="checkbox-med ml-3">-->
                <!--              <label for="has_soft_screen" class="ml-3">Soft-Screen actif</label>-->
                <!--              </div>-->
                <div class="ml-2 col-10 form-group d-flex">
                  <input type="checkbox" [(ngModel)]="concession.has_auto_treatment" class="form-control" id="has_auto_treatment"
                         name="has_auto_treatment" class="checkbox-med ml-3">
                  <label for="has_auto_treatment" class="ml-3">Traitement auto des notifications actif</label>
                </div>
              </div>
              <div class="form-group col-md-8" *ngIf="concession.has_auto_treatment">
                <div class="ml-2 col-11 form-group d-flex">
                  <input type="checkbox" class="form-control" [(ngModel)]="autoTreatmentWip" id="auto_treatment_wip"
                         name="auto_treatment_wip" class="checkbox-med ml-3">
                  <label for="has_auto_treatment" class="ml-3">Notifications pour travaux en cours</label>
                </div>
              </div>
              <div class="form-group col-md-8" *ngIf="concession.has_auto_treatment">
                <div class="ml-2 col-11 form-group d-flex">
                  <input type="checkbox" class="form-control" [(ngModel)]="autoTreatmentJ1" id="auto_treatment_j1"
                         name="auto_treatment_j1" class="checkbox-med ml-3">
                  <label for="has_auto_treatment" class="ml-3">Notifications pour restitution j-1</label>
                </div>
              </div>
              <div class="form-group col-md-10 d-flex" *ngIf="concession.has_auto_treatment">
                <label for="nb_days_min_auto_treatment">Nombre de jour minimum avant traitement auto :</label>
                <input type="number" [(ngModel)]="concession.nb_days_min_auto_treatment" class="form-control col-md-2 ml-3"
                       id="nb_days_min_auto_treatment" name="nb_days_min_auto_treatment">
              </div>
            </div>
            <div class="mb-3 d-flex w-100 justify-content-end col-11">
              <button (click)="SelectChangeHandlerSoumettre(false)" class="btn btn100px btn-dark p-2 mr-2">Valider</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Options PSTATUT & Srvice Tracking (MyBMW App)-->
      <div class="wrapper block-container">
        <div>
          <div class="header-block d-flex justify-content-between" (click)="togglePstatus()"><span>Options Pilot-Statut & MyBMW App</span><mat-icon>keyboard_arrow_down</mat-icon></div>
          <div class="d-flex input-ispacc justify-content-around flex-column" *ngIf="pstatusOpen">

            <div class="mt-4">
              <div class="ml-5">
                <input type="checkbox" [ngModel]="hasTimelinePstatut" (click)="setHasTimeline()">
                <label class="ml-2">Frise Pilot-Statut Active</label>
              </div>
              <div class="ml-5">
                <input type="checkbox" [ngModel]="hasMyBmwApp" (click)="setHasMyBmwApp()">
                <label class="ml-2">Service MyBMW App Actif</label>
              </div>
            </div>

            <div class="block-container border-bottom inner-collapse mt-4">
              <div class="header-block d-flex justify-content-between" (click)="togglePstatusCustomerResponse()">
                <span class="ml-3">Configuration de réponse client </span>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
              <div class="mb-3" style="font-weight: 300;" *ngIf="pstatusCustomerResponseOpen">
                <div class="ml-5">
                  <input type="checkbox" [(ngModel)]="pstatusOptions.hasCustomerResponse">
                  <label class="ml-2">Réponse client active</label>
                </div>
                <div *ngIf="pstatusOptions.hasCustomerResponse">
                  <div class="ml-5">
                    <label for="customerResponseStrictAfterComment" class="mr-3">Autoriser la réponse</label>
                    <select [(ngModel)]="pstatusOptions.customerResponseStrictAfterComment" name="customerResponseStrictAfterComment" id="customerResponseStrictAfterComment">
                      <option [ngValue]="false">À tout moment</option>
                      <option [ngValue]="true">Uniquement après un commentaire KIDI</option>
                    </select>
                  </div>
                  <div class="ml-5">
                    <label>Nombre de réponses client consécutives autorisées (0 = illimité)</label>
                    <input type="number" max="9" [(ngModel)]="pstatusOptions.customerResponseMaxIteration">
                  </div>
                  <div class="ml-5 mt-3">
                    <input type="checkbox" [(ngModel)]="pstatusOptions.hasCustomerResponseEmail">
                    <label class="ml-2">Envoyer un Email de notification au(x) CCS</label>
                  </div>
                  <div class="ml-5" *ngIf="pstatusOptions.hasCustomerResponseEmail">
                    <label for="customerResponseStrictAfterComment" class="mr-3">Notifier le CCS</label>
                    <select [(ngModel)]="pstatusOptions.customerResponseAppointmentSAOnly" name="customerResponseStrictAfterComment" id="customerResponseStrictAfterComment">
                      <option [ngValue]="false">Tous</option>
                      <option [ngValue]="true">Ayant envoyé le dernier commentaire KIDI</option>
                    </select>
                  </div>
                  <div class="ml-5 col-11" *ngIf="!pstatusOptions.customerResponseStrictAfterComment && pstatusOptions.hasCustomerResponseEmail && pstatusOptions.customerResponseAppointmentSAOnly">
                    <small style="color: grey"><i class="fas fa-info-circle mr-2"></i>Dans cette configuration, tant qu'il n'y a aucun commentaire KIDI dans l'historique, un email de notification sera envoyé à tous les utilisateurs possédant un rôle KIDI dans la concession.</small>
                  </div>
                </div>
                <div class="d-flex flex-row-reverse mr-5 mt-4">
                  <button (click)="submitConfigCustomerResponse()" type="button" class="btn btn-dark">Valider</button>
                </div>
              </div>
            </div>
            <!-- configuration réponse client pmessage -->
            <div class="block-container border-bottom inner-collapse">
              <div class="header-block d-flex justify-content-between" (click)="togglePmessageCustomerResponse()">
                <span class="ml-3">Configuration de réponse pilot-message </span>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
              <div class="mb-3" style="font-weight: 300;" *ngIf="pmessageCustomerResponseOpen">
                <div class="ml-5">
                  <input type="checkbox" [(ngModel)]="pmessageOptions.hasCustomerResponse">
                  <label class="ml-2">Réponse client active</label>
                </div>
                <div *ngIf="pmessageOptions.hasCustomerResponse">
                  <div class="ml-5">
                    <label for="customerResponseStrictAfterComment" class="mr-3">Autoriser la réponse</label>
                    <select [(ngModel)]="pmessageOptions.customerResponseStrictAfterComment" name="customerResponseStrictAfterComment" id="PmsgCustomerResponseStrictAfterComment">
                      <option [ngValue]="false">À tout moment</option>
                      <option [ngValue]="true">Uniquement après un message CCS</option>
                    </select>
                  </div>
                  <div class="ml-5">
                    <label>Nombre de réponses client consécutives autorisées (0 = illimité)</label>
                    <input type="number" max="9" [(ngModel)]="pmessageOptions.customerResponseMaxIteration">
                  </div>
                  <div class="ml-5 mt-3">
                    <input type="checkbox" [(ngModel)]="pmessageOptions.hasCustomerResponseEmail">
                    <label class="ml-2">Envoyer un Email de notification au(x) CCS</label>
                  </div>
                  <div class="ml-5" *ngIf="pmessageOptions.hasCustomerResponseEmail">
                    <label for="customerResponseStrictAfterComment" class="mr-3">Notifier le CCS</label>
                    <select [(ngModel)]="pmessageOptions.customerResponseAppointmentSAOnly" name="customerResponseStrictAfterComment" id="PmsgCustomerResponseStrictAfterComment">
                      <option [ngValue]="false">Tous</option>
                      <option [ngValue]="true">Ayant envoyé le dernier message</option>
                    </select>
                  </div>
                </div>
                <div class="d-flex flex-row-reverse mr-5 mt-4">
                  <button (click)="submitConfigPMessageCustomerResponse()" type="button" class="btn btn-dark">Valider</button>
                </div>
              </div>
            </div>
            <!-- configuration horaires pstatus -->
            <div class="block-container border-bottom inner-collapse">
              <div class="header-block d-flex justify-content-between" (click)="togglePStatusSchedule()">
                <span class="ml-3">Horaires atelier Pstatut <small *ngIf="!hasSchedule" class="text-danger">[Non-enregistrés]</small></span>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
              <div class="mb-3" style="font-weight: 300;" *ngIf="schedulePStatusOpen">
                <div *ngFor="let day of week" class="d-flex m-1">
                  <div style="width: 100px">{{day.name}}</div>
                  <div *ngIf="!day.isClosed" class="ml-4">
                    <input type="time" [(ngModel)]="day.morningStart" class="schedule-input"> - <input type="time"
                                                                                                       [(ngModel)]="day.morningEnd" class="schedule-input">
                    et <input type="time" [(ngModel)]="day.afternoonStart" class="schedule-input"> - <input type="time"
                                                                                                            [(ngModel)]="day.afternoonEnd" class="schedule-input">
                  </div>
                  <div *ngIf="day.isClosed" class="ml-4" style="width: 414px;">Fermé</div>
                  <div class="ml-2"><input type="checkbox" [ngModel]="!day.isClosed" (click)="scheduleIsClose(day.name)"></div>
                </div>
                <div class="d-flex flex-row-reverse mr-5 mt-4">
                  <button (click)="submitSchedule()" type="button" class="btn btn-dark">Valider</button>
                </div>
              </div>
            </div>
            <!-- configuration image complementaire pstatus -->
            <div class="block-container border-bottom inner-collapse mb-3">
              <div>
                <div class="header-block d-flex justify-content-between" (click)="toggleImagePstatus()"><span class="ml-3">Image complémentaire</span><mat-icon>keyboard_arrow_down</mat-icon></div>
                <div class="d-flex input-ispacc justify-content-around flex-column" *ngIf="imagePstatusOpen">
                  <div class="mb-3">
                    <input #uploadPstatusImageInput hidden="true" type="file" onclick="this.value=null"
                           (change)="uploadPstatusImage($event)" accept="image"/>
                    <div class="d-flex justify-content-between align-items-center">
                      <button matTooltip="Importer une image" type="button" id="btn-import-table" class="ml-4 mt-3 btn btn-outline-dark" (click)="uploadPstatusImageInput.click()">
                        Importer une image
                      </button>
                      <button *ngIf="contentSrc != ''" matTooltip="Supprimer image" type="button" class="ml-4 mt-3 btn btn-outline-danger" (click)="deletePstatutImage()">
                        Supprimer l'image
                      </button>
                    </div>
                    <div class="ml-5">
                      <small class="text-info">Taille max : 5mb</small>
                      <div class="file-name mt-2">{{fileNameFullsize}}</div>
                      <div style="display: inline-grid">
                        <img *ngIf="contentSrc != ''" src="{{contentSrc}}" alt="image" width="75%" class="fullscreen-image m-auto">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- Purge ISPACC -->
      <div class="wrapper block-container">
        <div>
          <div class="header-block d-flex justify-content-between" (click)="togglePurgeIspacc()"><span>Purge des rendez-vous KIDI</span><mat-icon>keyboard_arrow_down</mat-icon></div>
          <div class="d-flex input-ispacc justify-content-around flex-column" *ngIf="ispaccPurgeOpen">
            <div class="m-3 col-10">
              <div><strong>Retirer/masquer des rendez-vous sur l'affichage KIDI</strong></div>
              <div><small>Les rendez-vous sont conservés en base de données ainsi que dans le calcul Qlik</small></div>
              <label class="d-block mt-4" for="slug">Masquer les rendez-vous avec une date de réception avant</label>
              <div class="d-flex align-items-center">
                <span>Aujourd'hui - </span>
                <input class="mr-3 ml-3 form-control" style="width: 80px" type="number" id="nbDayPurge" [(ngModel)]="nbDayPurge">
                <span>jours</span>
              </div>
              <div class="mt-4 col-12 d-flex flex-column align-items-end justify-content-end">
                <button class="btn btn-outline-danger" type="button" [disabled]="nbDayPurge == null"
                        (click)="onPurgeAppointments(concessionId)">Démarrer la purge
                </button>
                <small style="color: grey;"><i class="fas fa-exclamation-triangle mr-2"></i>Cet action peut prendre plusieurs minutes</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<div class="col-sm ml-3 pt-3" style="margin-top: 34px;">
  <!-- Produit commercial -->
  <div>
    <!-- style="margin-top: 50px;" -->
      <div class="form-group ml-3">
      <button (click)="SelectChangeHandlerAjouterCommercial()" class="btn btn-warning d-flex align-items-center" data-toggle="modal" data-target="#popupCom"
        aria-pressed="false">
        <mat-icon class="mr-1">add_circle</mat-icon> Produit commercial
      </button>
      </div>
      <div class="form-group">
        <div class=" product-table">
          <table class="table table-bordered table-striped mb-0 mt-3" style="border: solid silver;">
            <thead>
              <tr>
                <th>Produit</th>
                <th>Version</th>
                <th>Supprimer</th>
                <th>Reinitialiser</th>
              </tr>
            </thead>

            <tbody>
              <ng-container *ngIf="commercialProductList?.length === 0">

                <h6>Aucun produit trouvé, veuillez en rajouter</h6>

              </ng-container>
              <ng-container *ngIf="commercialProductList?.length !== 0">
                <tr *ngFor="let produit of commercialProductList; let i = index" style="text-align: center" [id]="i">
                  <td>{{produit.nom_produit}}</td>
                  <td>{{produit.version}}</td>
                  <td>
                    <button (click)="dialogProduit(produit.produit_id, produit.nom_produit)" class="btn-dark btn btn-round-40 m-auto">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                  <td>
                    <button (click)="setVersion(produit.produit_id)" class="btn-dark btn btn-round-40 m-auto">
                      <mat-icon>loop</mat-icon>
                    </button>
                  </td>
                </tr>
              </ng-container>
              </tbody>
              </table>
             </div>
      </div>
   </div>
  <!-- Produit technical -->
  <div style="margin-top: 80px;">
  <div class="form-group ml-3">
                  <button (click)="SelectChangeHandlerAjouterTechnique()" class="btn btn-warning d-flex align-items-center" data-toggle="modal" data-target="#popupTech"
                    aria-pressed="false">
                    <mat-icon class="mr-1">add_circle</mat-icon> Produit technique
                  </button>
  </div>
    <div class="form-group">
                  <div class=" product-table">
                    <table class="table table-bordered table-striped mb-0 mt-3" style="border: solid silver;">
                      <thead>
                        <tr>
                          <th>Produit</th>
                          <th>Version</th>
                          <th>Supprimer</th>
                          <th>Reinitialiser</th>
                        </tr>
                      </thead>

                        <tbody>
                          <ng-container *ngIf="techniqueProductList?.length === 0">
                            <h6>Aucun produit trouvé, veuillez en rajouter</h6>
                          </ng-container>
                          <ng-container *ngIf="techniqueProductList?.length !== 0">
                            <tr *ngFor="let produit of techniqueProductList; let i = index" style="text-align: center" [id]="i">
                              <td>{{produit.nom_produit}}</td>
                              <td>{{produit.version}}</td>
                              <td>
                                <button (click)="dialogProduit(produit.produit_id, produit.nom_produit)"
                                  class="btn-dark btn btn-round-40 m-auto">
                                  <mat-icon>delete</mat-icon>
                                </button>
                              </td>
                              <td>
                                <button (click)="setVersion(produit.produit_id)" class="btn-dark btn btn-round-40 m-auto">
                                  <mat-icon>loop</mat-icon>
                                </button>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                        </table>
                        </div>
    </div>
</div>
</div>
<!-- ************Liste Des Produits Commercial*************************************************************************************************************************************************************** -->
<div id="">
  <div class="modal fade" id="popupCom" tabindex="-1" role="dialog" aria-labelledby="popupTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
       <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title">Liste Des Produits Commercial</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table-wrapper-scroll-y my-custom-scrollbar">
              <table class="table table-bordered table-striped mb-0">
               <thead>
                 <tr>
                    <th>No.</th>
                    <th>Nom de Produits</th>
                    <th>Section</th>
                 </tr>
               </thead>
                  <tbody>
                    <tr *ngFor="let produits of unsetCommercialProdutList; let i = index" style="text-align: center"
                       [id]="i">
                       <th>{{i+1}}</th>
                       <td>{{produits.nom_produit}}</td>
                       <td>
                         <input type="checkbox" [value]="produits.produit_id" (change)="checkClick($event)" />
                       </td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </div>
            <div class="modal-footer">
              <button (click)="rechargerPage()" type="button" class="btn btn-warning" data-dismiss="modal">Valider</button>
            </div>
       </div>
    </div>
  </div>
</div>
 <!-- ******************Liste Des Produits Technique********************************************************************************************************************************************************* -->
<div id="">
  <div class="modal fade" id="popupTech" tabindex="-1" role="dialog" aria-labelledby="popupTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="title">Liste Des Produits Technique</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
              <div class="table-wrapper-scroll-y my-custom-scrollbar">
                <table class="table table-bordered table-striped mb-0">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Nom de Produits</th>
                              <th>Selection</th>
                              </tr>
                            </thead>
                    <tbody>
                      <tr *ngFor="let produits of unsetTechniqueProdutList; let i = index" style="text-align: center" [id]="i">
                        <th>{{i+1}}</th>
                        <td>{{produits.nom_produit}}</td>
                        <td>
                              <input type="checkbox" [value]="produits.produit_id" (change)="checkClick($event)" />
                              </td>
                              </tr>
                    </tbody>
                </table>
              </div>
        </div>
        <div class="modal-footer">
                <button (click)="rechargerPage()" type="button" class="btn btn-warning" data-dismiss="modal">Valider</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ***************************************************************************************************************************************************************************** -->
  <div class="loader-maintenance" *ngIf="ispaccPurgeLoading">
    <div class="loader-dialog">
      <h4 class="m-3 text-center">Purge des rendez-vous KIDI en cours</h4>
      <div class="loader-container">
        <app-spinner></app-spinner>
      </div>
      <button class="btn btn-outline-dark m-3">Charger en arriere-plan</button>
    </div>
  </div>
