import { Component, OnInit } from '@angular/core';
import {DataConcessionService} from '../../utils/data-services/dataConcession.service';
import {DataInteractionService} from '../../utils/data-services/dataInteraction.service';
import {DataContactService} from '../../utils/data-services/dataContact.service';
import {DataFonctionService} from '../../utils/data-services/data-fonction.service';
import {ActivatedRoute, Router} from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-modification-contact',
  templateUrl: './modification-contact.component.html',
  styleUrls: ['./modification-contact.component.scss']
})
export class ModificationContactComponent implements OnInit {

  contact = {
    nom: '',
    prenom: '',
    telephone_fixe: '',
    telephone_portable: '',
    mail: '',
    commentaire: null,
    concessions_id: [],
    concessions: null,
    fonction_id: null,
    id_contact: null,
  };
  n = -1;
  dropdownConcessionSettings = {};
  dropdownConcessionList = [];
  concessionSelected = [];
  dropdownFonctionSettings = {};
  dropdownFonctionList = [];
  fonctionSelected = [];
  formError = false;
  idContact: any;
  isLoading = true;

  // tslint:disable-next-line:max-line-length
  constructor(private activeRoute: ActivatedRoute, private dataConcessionService: DataConcessionService, private dataInteractionService: DataInteractionService, private dataContactService: DataContactService, private dataFonctionService: DataFonctionService, private route: Router) { }

  ngOnInit() {
    this.idContact = Number(this.activeRoute.snapshot.paramMap.get('id'));
    this.dropdownConcessionSettings = {
      singleSelection: false,
      idField: 'concession_id',
      textField: 'nom_concession',
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownFonctionSettings = {
      singleSelection: true,
      idField: 'id_conf',
      textField: 'valeur',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dataContactService.getDealership().subscribe(concessions => {
      this.dropdownConcessionList = concessions;
      this.dataFonctionService.getFonction().subscribe(fonctions => {
        this.dropdownFonctionList = fonctions;
        this.getContact();
      });
    });
  }

  SelectChangeHandlerAnnuler() {
    this.route.navigate(['../gestion-contact']);
  }

  getConcessions(callback?): void {
    this.dataContactService.getDealership().subscribe(concessions => {
      this.dropdownConcessionList = concessions;
      if (callback)
        callback();
    });
  }
  getFonctions(callback?): void {
    this.dataFonctionService.getFonction().subscribe(fonctions => {
      this.dropdownFonctionList = fonctions;
      if (callback)
        callback();
    });
  }
  getContact() {
    this.dataContactService.getContactById(this.idContact).subscribe(contact => {
      this.contact = contact;
      this.contact.id_contact = this.idContact;
      this.fonctionSelected = this.dropdownFonctionList.filter(x => x.id_conf === contact.fonction_id);
      this.contact.concessions = contact.concessions.split(';');
      this.contact.concessions_id = contact.concessions_id.split(';');
      let selectedConcession = [];
      for (const concession of this.contact.concessions) {
        const userConcession = this.dropdownConcessionList.filter(x => x.nom_concession === concession);
        selectedConcession.push( {concession_id: userConcession[0].concession_id, nom_concession: userConcession[0].nom_concession} );
      }
      this.concessionSelected = selectedConcession;
      this.isLoading = false;
    });
  }
  checkForm(): void {
    this.formError = false;
    const notRequiredFields = ['tel_fix', 'tel_mobile', 'email', 'commentaire'];
    for (const item of Object.entries(this.contact)) {
      if (!notRequiredFields.includes(item[0]) && (item[1] == '' || item[1] == null)) {
        this.formError = true;
      }
    }
  }
  /***
   * Create a new concession
   ***/
  SelectChangeHandlerCreer() {
    this.formError = false;
    this.contact.concessions_id = [];
    for (const concession of this.concessionSelected) {
      this.contact.concessions_id.push(concession.concession_id);
    }
    const body = JSON.stringify(this.contact);
    if (!this.formError) {
      this.dataContactService.updateContact(body).subscribe( response => {
        this.route.navigate(['../gestion-contact']);
      }, error => {
        swal.fire({
          title: 'Erreur!',
          text: 'Un problème est survenu lors de la création du contact',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      });
    }
  }
  onConcessionSelect(item: any) {

  }

  onConcessionDeselect(item: any) {
    for (let i = 0; i < this.concessionSelected.length; i++) {
      if (this.concessionSelected[i] === item) {
        this.concessionSelected.splice(i, 1);
      }
    }
  }
  onFonctionSelect(item: any) {
    this.fonctionSelected = [item];
    this.contact.fonction_id = item.id_conf;
  }

  onFonctionDeselect(item: any) {
    for (let i = 0; i < this.fonctionSelected.length; i++) {
      if (this.fonctionSelected[i] === item) {
        this.fonctionSelected.splice(i, 1);
        this.contact.fonction_id = null;
      }
    }
  }

}
